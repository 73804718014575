* {
  font-family:sans-serif;
}
.root {
  postion: relative;
}
button.add {
  font-size:1em;
  cursor:pointer;
  border: none;
  outline: 0;
  color:#999;
  background-color:#eee;
  height:20px;
  width:20px;
  border-radius:20px;
  margin:0;
  padding:0;
}
button.add:hover{
  background-color:#ddd;
  color:#000;
}
button.add:focus{
  background-color:#ddd;
}
.edit {
  cursor:pointer;
  position:absolute;
  top:0;right:0;
  border: none;
  opacity:0;
  cursor:pointer;
  height:12px;
  width:12px;
  margin:10px;
  border-radius:20px;
  line-height:20px;
  color:#999;
  background-color:transparent;
  padding:5px;
}
li.react-tabs__tab {
  position: relative;
}
li.react-tabs__tab .edit {
  margin:2px;
  right: -20px;
}
li.site:hover .edit, li.react-tabs__tab:hover .edit, li.site .edit:focus{
  opacity:1;
}
li.site .edit:focus{
  outline:0;
  height:20px;
  width:20px;
  border-radius:20px;
  background-color:#ddd;
}
li.site .edit::-moz-focus-inner{
  border:0;
}
.edit:hover {
  background-color:#ddd;
  color:black;
}


/* TABS */


ul.react-tabs__tab-list {
  display:flex;
  list-style-type: none;
  justify-content: center;
  width: 100%;
  font-size: 1em;
  text-transform: uppercase;
  color: #D5D5D5;
  font-family: Sans-Serif;
  font-weight: bold;
  cursor: default;
  border: 0;
  padding: 0;
  margin:60px 0 30px 0;

}
ul.react-tabs__tab-list button.add{
  position:relative;
  float:left;
  margin-top:22px;
  margin-left:25px;
}
li.react-tabs__tab {
  display:inline-block;
  cursor:pointer;
  text-align:center;
  padding:5px 10px;
  margin:20px;
  border-radius:20px;
}
li.react-tabs__tab:hover, li.react-tabs__tab--selected {
  color: black;
}
li.react-tabs__tab:focus{
  outline:none;
  color:rgb(158, 0, 255);
}
.react-tabs__tab-panel {
  position: relative;
}

ul#sitelist{
  position:relative;
  width:100%;
  max-width:820px;
  min-width:300px;
  padding:0;
  margin:0 auto;
}

.remove:hover, .remove:focus{
  outline:none;
  text-decoration:none;
  color:red;
  cursor:pointer;
}


/* ITEMS */


.site {
  position: relative;
}
.site a{
  display:block;
  text-decoration:none;
  padding:0;
  margin:0;
  width:100%;
  height:100%;
  display:table;
}
a:hover{
  text-decoration:underline;
  text-decoration-color:rgb(158, 0, 255);
}
a:focus{
  outline:none;
}
li a:focus{
  outline:none;
  transition: all 200ms;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,.2);
}
.site p{
  padding:0;margin:0;
  font-size:18px;
  text-decoration: none;
  text-align:center;
  display:table-cell;
  vertical-align: middle;
  color:#000;
  padding: 0 10px;
}
ul#sitelist {
  display:flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: auto;
  padding:0;
  padding-left:70px;
}
ul#sitelist li{
  position: relative;
  display: flex;
  align-items:center;
  justify-content:center;
  list-style:none;
  width:184px;
  height:140px;
  min-width:90px;
  min-height:90px;
  margin:5px;
  border-radius:5px;
  background-color:rgb(244, 244, 244);
}
#sitelist li:hover{
  transition: all 200ms;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,.2);
}
ul#sitelist button.add{
  display: flex;
  align-items:center;
  justify-content:center;
  margin:5px;
  padding:20px;
  font-size:1em;
  margin: 55px 0 0 0;
  width:10px;
  height:10px;
  line-height:0;
  border-radius:20px;
}
ul#sitelist button.add:hover{
  background-color:#ddd;
}
@media only screen and (max-width: 900px) {
    ul#sitelist {
        margin:0;
        padding:0;
        max-width:none;
    }
    ul#sitelist li {
        width: calc(33.33vw - 21px);
        height: calc(33.33vw - 16vw);
    }
    ul#sitelist button.add{
        margin:7% 0 5% 0;
    }
    ul.react-tabs__tab-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-end;
      align-content: flex-start;
      width:100%;
    }
    ul.react-tabs__tab-list li{
      margin:10px;
    }
    ul.react-tabs__tab-list button.add{
      top:-15px;
      margin:0;
      padding:0;
    }
}
@media only screen and (max-width: 600px) {
    ul#sitelist li {
        width: calc(50vw - 27px);
        height: calc(50vw - 16vw);
    }
}
